@import "system-font-css/_system-font";

// 1. Custom variables and variable overwrites.
@import "theme/variables";

// 2. Import default variables and available mixins.
@import "uikit/variables-theme";
@import "uikit/mixins-theme";

// 3. Custom mixin overwrites.
@import "theme/mixins";

// 4. Import UIkit.
@import "theme/uikit";

// 5. Other vendor styles
// @import "syntax-highlighting/bw";
